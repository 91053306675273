import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CONFIG } from './config';

export const COUNTRYID = {
    US: 2,
    UK: 7
}

export const COUNTRYCODE = {
    US: 'USA',
    UK: "UK"
}

export const CURRENCYCODE = {
    US: 36,
    UK: 163
}

export const CURRENCY = {
    US: '$',
    UK: '£'
}

export const REGIONS = {
    US: 1,
    AUS: 2,
    UK: 3,
}

export const USERTYPES = {
    Admin: 15,
    Broker: 10,
}

export const REPORTTYPES = {
    MarketView: 1,
    Historical: 2,
    Partner: 3,
}

export const STATUS = {
    Active: 1,
    Draft: 2,
    InActive: 3,
    DeleteApprove: 4
}
export const VENDORTYPE = {
    Free: 1,
    Premium: 2
}
export const PROPERTYSTATUS = {
    Approved: 1,
    PendingApproval: 2,
    Draft: 3,
    Sold: 4,
    Closed: 5,//expired
    Inactive: 6,//withdrawn
    SalePending: 7,
}

export const USERSTATUS = {
    Active: 1,
    Inactive: 2,
    Deleted: 3,
    SubscriptionCancelled: 4,
    PaymentLocked: 5
}

export const BROKERTYPES = {
    Unauthorized: 1,
    Broker: 2,
    PremiumBroker: 3
}

export const PROPERTYTYPES = {
    RV: 1,
    Campground: 2,
    RVandCampground: 3,
    RVCampgroundandLongTermnly: 4,
    RVandLongTerm: 5
}

export const PRICEOPTION = {
    MarketBid: 1,
    PriceUndisclosed: 2,
    PriceDeterminedbyMarket: 3,
    ContactBrokerforPrice: 4,
    CallforOffers: 5,
    Auction: 6
}

export const contactAboutOptions = [
    "Financing options for RVParkIQ assets",
    "Professional RVParkIQ property management",
    "Property insurance quotes for RVParkIQ properties",
    "Comprehensive overview of RVParkIQ facilities in your market",
    "Entitlement, permit and RVParkIQ construction quotes",
    "Selling my property",
];

export function getFormattedFloat(number) {
    var value = parseFloat(number).toLocaleString('en-US');
    return value !== "NaN" ? value : '';
}

export function getFormattedRange(number) {
    if (number.includes('-')) {
        let splitValue = number.split("-");
        let value1 = parseFloat(splitValue[0].trim()).toLocaleString('en-US');
        let value2 = parseFloat(splitValue[1].trim()).toLocaleString('en-US');
        return value1 != "NaN" && value2 != "NaN" ? value1 + " - " + value2 : ''
    }
    var value = parseFloat(number).toLocaleString('en-US');
    return value !== "NaN" ? value : '';
}

export function getFormattedInt(number) {
    var value = parseInt(number, 10).toLocaleString('en-US');

    return value !== "NaN" ? value : '';
}

export function getPropertyViewUrl(permalink) {
    return `${window.location.protocol}//${window.location.host}/property/${permalink}`;
}

export function getFormattedDecimal(number) {
    let dotSplit = number.split(".");
    var formattedNumber = getFormattedInt(dotSplit[0].replace(/,/g, ''));
    formattedNumber = dotSplit.length > 1 ? formattedNumber + "." + dotSplit[1] : formattedNumber;

    return formattedNumber !== "NaN" ? formattedNumber : '';
}

export function getPager(totalItems, currentPage = 1, pageSize) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage, endPage;
    if (totalPages <= 5) {
        // less than 5 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 5 total pages so calculate start and end pages
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // calculate start and end record count
    const startRecordCount = (currentPage - 1) * pageSize + 1;
    let endRecordCount = totalItems;
    if (pageSize < totalItems) {
        endRecordCount = pageSize * currentPage;
        if (endRecordCount > totalItems) {
            endRecordCount = totalItems;
        }
    }

    // create an array of pages to ng-repeat in the pager control
    const range = (start, end) => Array.from(Array(end - start + 1), (_, i) => start + i);
    const pages = range(startPage, endPage);
    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        startRecordCount: startRecordCount,
        endRecordCount: endRecordCount,
        pages: pages
    };
}

export function normalizeInput(value, previousValue) {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (CONFIG.REGION_ID !== REGIONS.UK) {
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

            //return `${currentValue.slice(0, 0)}(${currentValue.slice(0, 3)})-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
            return `${currentValue.slice(0, 0)}${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
        else {
            return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    } else {
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;

            return `${currentValue.slice(0, 0)}${currentValue.slice(0, 3)} ${currentValue.slice(3, 7)} ${currentValue.slice(7, 11)}`;
        }
        else {
            return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 7)} ${currentValue.slice(7, 11)}`;
        }
    }
}

export function deNormalizeInput(value) {
    return value.replace(/[^\d]/g, '');
}

export function prepareStringForUrl(str) {
    return str.replace(/[^\w]/gi, '-').replace(/---/g, '-').replace(/--/g, '-');
}

export function randomStr() {
    var arr = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var len = 8;
    var ans = '';
    for (var i = len; i > 0; i--) {
        ans +=
            arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
}

export function thumbnailPropertyData(property) {
    var result = [];
    var val = null;

    if (property.LotSize) {
        val = (getFormattedFloat(property.LotSize)).toString();
        val = property.LotSizeMeasurementId === 1 ? val + ' Acres' : val + ' AC';
        result.push({ Name: 'Acreage', Value: val });
    }
    if (property.NoofLots) {
        val = getFormattedFloat(property.NoofLots);
        result.push({ Name: 'Lots Count', Value: val });
    }
    if (property.amentities) {
        val = property.amentities;
        result.push({ Name: 'Amenities', Value: substringStripHtmlTag(val, 20) });
    }
    if (result.length < 2) {
        if (property.GSF) {
            val = property.GSF + ' SF';
            result.push({ Name: 'GSF', Value: val });
        }
        if (property.NOI) {
            val = property.NOICurrencyCode + getFormattedInt(property.NOI);
            result.push({ Name: 'NOI', Value: val });
        }
        if (result.length < 2) {
            if (property.CapRate) {
                val = getFormattedFloat(property.CapRate) + '%';
                result.push({ Name: 'Cap Rate', Value: val });
            }
            if (property.Occupancy) {
                val = getFormattedFloat(property.Occupancy) + '%';
                result.push({ Name: 'Long-Term Guest Occupancy', Value: val });
            }
        }
    }

    return (
        <span>
            {result.map((data, index) => {
                if (index <= 2) {
                    return (<p><FontAwesomeIcon icon={faCaretRight} />{data.Name}:<span> {data.Value}</span></p>);
                }
                else {
                    return null;
                }
            })}
        </span>
    );
}

export function getPropertyTypeClass(typeId, isLegend = false) {
    switch (typeId) {
        case PROPERTYTYPES.RV:
            return isLegend ? 'orange-label' : 'redtag-s';

        case PROPERTYTYPES.Campground:
            return isLegend ? 'dev-label' : 'bluetag-s';

        case PROPERTYTYPES.RVandCampground:
            return isLegend ? 'con-label' : 'bluetag-s greentag-s';

        case PROPERTYTYPES.RVCampgroundandLongTermnly:
            return isLegend ? 'land-label' : 'bluetag-s orangetag-s';

        case PROPERTYTYPES.RVandLongTerm:
            return isLegend ? 'land-blue-label' : 'bluetag-s yellowtag-s';

        default:
            return '';
    }
}

export function getPropertyStatus(statusId) {
    switch (statusId) {
        case PROPERTYSTATUS.Approved:
            return 'Sale';

        case PROPERTYSTATUS.SalePending:
            return 'Under Contract';

        case PROPERTYSTATUS.Sold:
            return 'Sold';

        default:
            return '';
    }
}

export function getPropertyStatusClass(statusId) {
    switch (statusId) {
        case PROPERTYSTATUS.Approved:
            return 'bluetag-s saletag-s';

        case PROPERTYSTATUS.SalePending:
            return 'bluetag-s redtag-s';

        case PROPERTYSTATUS.Sold:
            return 'bluetag-s redtag-s';

        default:
            return '';
    }
}

export function getDateFormat() {
    return CONFIG.REGION_ID !== REGIONS.UK ? "L" : "DD/MM/YYYY";
}

export function getDateFormatString() {
    return CONFIG.REGION_ID !== REGIONS.UK ? "LL" : "DD/MM/YYYY";
}

// export function stripHtmlTags(source, stripNewLines = false) {
//     var content = source.replace(/<[^>]+>/g, '');

//     if (stripNewLines)
//         content = content.Replace("\n", " ");

//     return content;
// }

// export function substringStripHtmlTag(source, length) {
//     var content = stripHtmlTags(source);
//     content = content.replaceAll("&rsquo;", "’");
//     content = content.replaceAll("&nbsp;", " ");

//     if (content.length > length) {
//         content = content.substring(0, length) + '...';
//     }
//     return content;
// }

export function stripHtmlTags(source, stripNewLines = false, maxLength = null) {
    var content = source.replace(/<[^>]+>/g, '');
  
    // Remove [caption ...][/caption] tags
    content = content.replace(/\[caption[^\]]*\](.*?)\[\/caption\]/g, '');
  
    // Replace specific HTML entities
    content = content.replace(/&rsquo;/g, '’');
    content = content.replace(/&nbsp;/g, ' ');
  
    if (stripNewLines) {
      content = content.replace(/\n/g, ' ');
    }
  
    if (maxLength !== null && content.length > maxLength) {
      content = content.substring(0, maxLength) + '...';
    }
  
    return content;
  }
  
  export function substringStripHtmlTag(source, length) {
    return stripHtmlTags(source, false, length);
  }